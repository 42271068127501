import { fetchAllList } from 'share/services/api'
import { findById, mapByIds } from 'share/services/utils'
import { I18n } from 'share/services/i18n'

export default ["Resty", "Company", "User", 'Country', (Resty, Company, User, Country) => Resty('locations', klass => {
  klass.getDetailed = id => Promise.all([
    klass.fetchOne(id),
    klass.getFormData()
  ]).then(([location, dict]) => {
    location.$country = findById(dict.countries, location.countryId);
    location.$externalUnits = mapByIds(dict.externalUnits, location.externalUnitIds);
    location.$meta.dict = dict;

    return Promise.all([
      Company.fetchOne(location.companyId),
      User.getListByIds([location.createdBy, location.updatedBy])
    ]).then(([company, users]) => {
      location.$company = company;
      location.$createdBy = users.find(user => user.id === location.createdBy);
      location.$updatedBy = users.find(user => user.id === location.updatedBy);
      return location;
    });
  })

  klass.getFormData = () => Promise.all([
    Country.getListWithZones(),
    fetchAllList('external_units'),
    fetchAllList('external_unit_types', { path: '/dictionaries/external_unit_types' })
  ]).then(([countries, externalUnits, externalUnitTypes]) => {
    externalUnits.forEach(externalUnit =>
      externalUnit.$externalUnitType = findById(externalUnitTypes, externalUnit.externalUnitTypeId));

    return {
      countries,
      externalUnits,
      externalUnitTypes,
      locales: Object.keys(window.EVA.I18n).map(id => ({ id, name: I18n.t(`ui.locales.${id}`) }))
    };
  })
})];
