import "share/angular"

import evaNamespace from 'ng/directives/eva-namespace'
import "script-loader!ng/directives/eva-unit-address"
import "script-loader!ng/directives/ng-wizard-listener"
import Company from "ng/repositories/company"
import "script-loader!ng/repositories/external-unit-type"
import ExternalUnit from "ng/repositories/external-unit"
import Location from  "ng/repositories/location"

app
  .directive('evaNamespace', evaNamespace)
  .service('Company', Company)
  .service('ExternalUnit', ExternalUnit)
  .service('Location', Location);

app.schema.extendMixins({
  companies: {
    isLocked() {
      return this.$locations.some(location => location.isLocked());
    }
  },
  external_units: {
    isLocked: function() {
      return this.locationIds.length > 0;
    }
  },
  locations: {
    isCloud: function() {
      return this.serverType == 'cloud';
    },
    isLocked: function() {
      return this.externalUnitIds.length > 0;
    }
  }
});

app.schema.extendProcesses({
  admins(admin) {
    admin.$name = app.schema.utils.name(admin.firstName, admin.lastName);
  },
  external_units(unit) {
    unit.$adminName = app.schema.utils.name(unit.adminFirstName, unit.adminLastName);
  },
  locations(location) {
    location.$adminName = app.schema.utils.name(location.adminFirstName, location.adminLastName);
  }
});
