import { fetchOne, fetchAllList } from 'share/services/api'
import { mapByIds } from 'share/services/utils'

export default ['Resty', 'Country', (Resty, Country) => Resty('external_units', (klass) => {
  klass.getFormData = () => Promise.all([
    fetchAllList('locations'),
    Country.getListWithZones(),
    fetchAllList('external_unit_types', { path: '/dictionaries/external_unit_types' })
  ]).then(([locations, countries, externalUnitTypes]) => ({ locations, countries, externalUnitTypes }));

  klass.getDetailed = (id) => Promise.all([
    fetchOne(`/external_units/${id}`, 'external_units', 'external_unit'),
    fetchAllList('locations')
  ]).then(([externalUnit, locations]) => {
    externalUnit.$locations = mapByIds(locations, externalUnit.locationIds);

    return Promise.all([
      fetchOne(`/dictionaries/countries/${externalUnit.countryId}`, 'countries', 'country')
        .then(country => externalUnit.$country = country),
      fetchOne(`/dictionaries/external_unit_types/${externalUnit.externalUnitTypeId}`, 'external_unit_types', 'external_unit_type')
        .then(externalUnitType => externalUnit.$externalUnitType = externalUnitType)
    ]).then(() => ([externalUnit, { locations }]));
  });
})];
