const isInvalid = (input, text) => {
  if(input.search(/[0-9a-z]/) != -1) return false;

  if((input === '.') && text.indexOf('.') == -1) return false;

  return true;
}

export default () => ({
  link(_scope, element) {
    element.on('keypress', (e) => {
      if(e.charCode == 0) return;

      let input = String.fromCharCode(e.charCode);
      let text = element.val();
      if(isInvalid(input, text)) e.preventDefault();
    });
  }
});
