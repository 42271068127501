export default ["Resty", (Resty) => Resty("companies", (klass) => {
  klass.tableList = (page) => klass.page(page).then((companies) =>
    Promise.all(companies.map(company => company.loadNestedResources('locations'))).then(() => companies)
  );

  klass.withLocationsCount = (page, options) => klass.page(page, options).then(list =>
    Promise.all(list.map((company) => company.loadNestedResourcesCount('locations'))).then(() => list),
    Promise.reject
  );

  klass.loadFormData = (id) => klass.fetchOne(id).then((company) =>
    company.loadNestedResources('locations').then(() => company)
  );
})];
